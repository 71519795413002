class DisableBtn {
    constructor() {

        // Funkcja do wyłączania przycisku rejestracji nauczyciela
        function setDisableBtnTeacher() {
            let currentDate = new Date();
            const registrationStartDate = new Date('2024-09-08');  // Zmień tę datę na faktyczną datę początkową rejestracji
            const registrationEndDate = new Date('2024-10-01');  // Zmień tę datę na faktyczną datę końcową rejestracji

            let currentDateCompare = currentDate.valueOf();
            let registrationStartDateCompare = registrationStartDate.valueOf();
            let registrationEndDateCompare = registrationEndDate.valueOf();

            console.log('Obecna data (nauczyciel):', currentDate);

            if (document.querySelector('#register-btn-teacher')) {
                const registerBtn = document.querySelector('#register-btn-teacher');
                let disabledBtn = document.createElement("button");
                disabledBtn.innerHTML = `<span>Rejestracja zamknięta</span>`;
                disabledBtn.classList.add('btn', 'btn-secondary');
                disabledBtn.disabled = true;

                let unavailableBtn = document.createElement("button");
                unavailableBtn.innerHTML = `<span>Rejestracja niedostępna</span>`;
                unavailableBtn.classList.add('btn', 'btn-secondary');
                unavailableBtn.disabled = true;

                // Jeśli data jest przed datą początkową, pokaż "rejestracja niedostępna"
                if (currentDateCompare < registrationStartDateCompare) {
                    console.log('Data początkowa (nauczyciel):', registrationStartDate);
                    registerBtn.replaceWith(unavailableBtn);
                } else if (currentDateCompare > registrationEndDateCompare) {
                    console.log('Data końcowa (nauczyciel):', registrationEndDate);
                    // Jeśli data jest po dacie końcowej, pokaż "rejestracja zamknięta"
                    registerBtn.replaceWith(disabledBtn);
                }
            }
        }

        // Funkcja do wyłączania przycisku rejestracji studenta
        function setDisableBtnStudent() {
            let currentDate = new Date();
            const registrationStartDate = new Date('2024-10-06');  // Zmień tę datę na faktyczną datę początkową rejestracji
            const registrationEndDate = new Date('2024-10-19');  // Zmień tę datę na faktyczną datę końcową rejestracji

            let currentDateCompare = currentDate.valueOf();
            let registrationStartDateCompare = registrationStartDate.valueOf();
            let registrationEndDateCompare = registrationEndDate.valueOf();

            if (document.querySelector('#register-btn-student')) {
                const registerBtn = document.querySelector('#register-btn-student');
                let disabledBtn = document.createElement("button");
                disabledBtn.innerHTML = `<span>Rejestracja zamknięta</span>`;
                disabledBtn.classList.add('btn', 'btn-secondary');
                disabledBtn.disabled = true;

                let unavailableBtn = document.createElement("button");
                unavailableBtn.innerHTML = `<span>Rejestracja niedostępna</span>`;
                unavailableBtn.classList.add('btn', 'btn-secondary');
                unavailableBtn.disabled = true;

                // Jeśli data jest przed datą początkową, pokaż "rejestracja niedostępna"
                if (currentDateCompare < registrationStartDateCompare) {
                    registerBtn.replaceWith(unavailableBtn);
                } else if (currentDateCompare > registrationEndDateCompare) {
                    // Jeśli data jest po dacie końcowej, pokaż "rejestracja zamknięta"
                    registerBtn.replaceWith(disabledBtn);
                }
            }
        }

        setDisableBtnTeacher();
        setDisableBtnStudent();
    }
}

export default DisableBtn;
